h1 {
  color: #340a03;
  text-align: center;
  padding-bottom: 15px;
}

p {
  color: #87361b;
  text-align: justify;
  text-decoration: none;
}

a {
 color: #ff7000;
 text-decoration: none;
}

a:hover {
  color: #f5d60a;
}

a.fs-1 {
  color: #ff7000;
  padding-left: 15px;
  text-decoration: none;
}

a.fs-1:hover {
  color: #f5d60a;
}

a.nav-link.fs-2 {
  color: #ff7000;
}

a.nav-link.fs-2:hover {
  color: #f5d60a;
}

footer {
  color: #340a03;
  background-color: #bc916a;
}

.main {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}

.section {
  flex: 1;
  color: #87361b;
  padding-top: 20px;
  background-color: #eadad1;
}

.navbar {
  color: #ff7000;
  background-color: #340a03;
}

.card p {
  color: #340a03;
}

.card-body {
  text-align: justify;
  background-color: #eadad1;
}